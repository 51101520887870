// import React, { useState, useEffect, useRef } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import {
//   faTwitter,
//   faLinkedin,
//   faInstagram,
//   faFacebook,
// } from "@fortawesome/free-brands-svg-icons";
// import {
//   PrimaryHeading,
//   PrimaryText,
//   SecondaryHeading,
// } from "../components/Text";
// import Button from "../components/Button";
// import { Icons } from "../assets/icons/icons";
// const Navbar = () => {
//   const [showSidebar, setShowSidebar] = useState(false);
//   const [activeLink, setActiveLink] = useState(window.location.pathname);

//   useEffect(() => {

//     const handleScroll = () => {
//       const scrollPosition = window.scrollY + window.innerHeight / 2;

//       links.forEach(([title, url]) => {
//         let targetElement;

//         if (url === "/") {
//           targetElement = document.querySelector("body");
//         } else {
//           targetElement = document.querySelector(url);
//         }

//         if (targetElement) {
//           const elementTop = targetElement.offsetTop;
//           const elementBottom = elementTop + targetElement.offsetHeight;

//           if (scrollPosition >= elementTop && scrollPosition <= elementBottom) {
//             setActiveLink(title);
//           }
//         }
//       });
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []); // Run this effect only once on component mount

//   const links = [
//     ["HOME", "/"],
//     ["ABOUT", "#Know"],
//     ["SERVICES", "#Service"],
//     ["PORTFOLIO", "#Work"],
//     ["TESTIMONIAL", "#Review"],
//     ["CONTACT", "#Contact"],
//   ];

//   const socialLinks = [
//     ["Twitter", "https://twitter.com/example"],
//     ["Facebook", "https://github.com/example"],
//     ["LinkedIn", "https://www.linkedin.com/in/example"],
//     ["Instagram", "https://github.com/example"],
//     ["GitHub", "https://github.com/example"],
//   ];
//   const currentPath = window.location.pathname; // Get the current path

//   return (
//     <>
//       {/* Navbar */}
//       <nav className="fixed lg:sticky top-0 z-[99] w-[100%]  text-center border-b bg-white justify-between px-4 xl:px-[4rem] flex items-center">
//         {/* ...existing code... */}
//         <div className=" lg:w-1/4 pt-4">
//           <a href="/">
//             {" "}
//             <PrimaryHeading
//               title={"Ahsan Akram"}
//               style={
//                 "lg:text-[25px] border-b-2 pb-1 b-w-[100px] border-dark-primary font-extrabold lg:w-[190px] sm:w-[220px]"
//               }
//             />
//           </a>
//         </div>
//         <div class="w-2/3 hidden lg:block mx-auto">
//           <div class="navLink">
//             {links.map(([title, url]) => (
//               <a
//                 key={title}
//                 href={url}
//                 className={`block mt-4 font-semibold lg:inline-block lg:text-[15px] xl:text-[18px] ${
//                   title === activeLink ? "text-black bg-black text-white" : ""
//                 } lg:mt-0  mr-4 xl:mr-8`}
//                 onClick={() => setActiveLink(url)}
//               >
//                 {title}
//               </a>
//             ))}
//           </div>
//         </div>

//         {/* Burger Icon */}
//         <div className="w-1/4 hidden lg:block ">
//           <div className="flex justify-center ">
//             {socialLinks.map(([title, url]) => (
//               <a
//                 key={title}
//                 href={url}
//                 className="duration-500 ease-in-out hover:-translate-y-1 hover:text-gray-400   p-[8px] text-[20px]"
//               >
//                 <FontAwesomeIcon
//                   icon={
//                     title === "Twitter"
//                       ? faTwitter
//                       : title === "Instagram"
//                       ? faInstagram
//                       : title === "LinkedIn"
//                       ? faLinkedin
//                       : title === "Facebook"
//                       ? faFacebook
//                       : null
//                   }
//                 />
//               </a>
//             ))}
//           </div>
//         </div>
//         <div className="w-1/4 lg:hidden ">
//           <Button
//             onClick={() => setShowSidebar(!showSidebar)}
//             style={"w-[50px]"}
//             text={
//               showSidebar ? (
//                 <FontAwesomeIcon icon={Icons.Close} />
//               ) : (
//                 <FontAwesomeIcon icon={Icons.Bars} />
//               )
//             }
//           />
//         </div>
//       </nav>

//       {/* Sidebar */}
//       <div
//         className={`sm:W-[100%] fixed w-[100%] z-[1] h-[100vh] flex items-center justify-center lg:hidden top-0 right-0 bg-gray-100 p-10 ${
//           showSidebar ? "block" : "hidden"
//         }`}
//       >
//         <div id="sidebar" class=" text-center  p-">
//           {links.map(([title, url]) => (
//             <a key={title} href={url} className=" block mt-4 font-semibold">
//               {title}
//             </a>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Navbar;
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareXTwitter,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import {
  PrimaryHeading,
  PrimaryText,
  SecondaryHeading,
} from "../components/Text";
import Button from "../components/Button";
import { Icons } from "../assets/icons/icons";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [scrollActiveEnabled, setScrollActiveEnabled] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollActiveEnabled) {
        const scrollPosition = window.scrollY + window.innerHeight / 2;

        links.forEach(([title, url]) => {
          let targetElement;

          if (url === "/") {
            targetElement = document.querySelector("body");
          } else {
            targetElement = document.querySelector(url);
          }

          if (targetElement) {
            const elementTop = targetElement.offsetTop;
            const elementBottom = elementTop + targetElement.offsetHeight;

            if (
              scrollPosition >= elementTop &&
              scrollPosition <= elementBottom
            ) {
              setActiveLink(title);
            }
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollActiveEnabled]);

  const links = [
    ["HOME", "/"],
    ["ABOUT", "#Know"],
    ["SERVICES", "#Service"],
    ["RESUME", "#Resume"],
    ["PORTFOLIO", "#Work"],
    ["TESTIMONIAL", "#Review"],
    ["FAQ", "#Faq"],
    ["CONTACT", "#Contact"],
  ];

  const socialLinks = [
    ["Twitter", "https://twitter.com/AhsanAkram_tech"],
    [
      "Facebook",
      "https://www.facebook.com/ahsan.siddiqui.90813?mibextid=ZbWKwL",
    ],
    ["LinkedIn", "https://www.linkedin.com/in/muhammad-ahsan-a-9927b4a9/"],
    ["Instagram", "https://github.com/example"],
    ["GitHub", "https://github.com/example"],
  ];

  const handleNavLinkClick = title => {
    setScrollActiveEnabled(false); // Disable scroll active
    setActiveLink(title);
    const url = links.find(link => link[0] === title)[1];
    const targetElement = url === "/" ? document.querySelector("body") : document.querySelector(url);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setTimeout(() => {
      setScrollActiveEnabled(true); // Re-enable scroll active after a delay
    }, 1000); // You can adjust the delay as needed
  };

  return (
    <>
      {/* Navbar */}
      <nav className="nav-main fixed lg:sticky top-0 z-[99] w-[100%]  text-center border-b bg-white justify-between px-4 xl:px-[4rem] flex items-center">
        {/* ...existing code... */}
        <div className="pt-4 ">
          <a href="/">
            {" "}
            <PrimaryHeading
              title={"Muhammad Ahsan Akram"}
              style={
                "lg:text-[18px] border-b-2 text-sm sm:w-[450px] lg:mr-0 lg:w-[280px] pb-1 b-w-[100px] border-dark-primary md:font-extrabold "
              }
            />
          </a>
        </div>
        <div className="w-[100%] hidden  lg:block mx-auto ">
          <div className="navLink">
            {links.map(([title, url]) => (
              <a
                key={title}
                href={url}
                className={`block mt-4 font-semibold lg:inline-block lg:text-[14px] xl:text-[15px] ${
                  title === activeLink
                    ? "text-black bg-[#ffe38a] px-1  text-white"
                    : ""
                } lg:mt-0  mr-3 xl:mr-5`}
                onClick={() => handleNavLinkClick(title)}
              >
                {title}
              </a>
            ))}
          </div>
        </div>

        {/* Burger Icon */}
        <div className="w-[200px] hidden lg:block  ">
          <div className="flex justify-end ">
            {socialLinks.map(([title, url]) => (
              <a
                target="blank"
                key={title}
                href={url}
                className="duration-500 ease-in-out hover:-translate-y-1 hover:text-gray-400   p-[8px] text-[20px]"
              >
                <FontAwesomeIcon
                  icon={
                    title === "Twitter"
                      ? faSquareXTwitter
                      : title === "LinkedIn"
                      ? faLinkedin
                      : title === "Facebook"
                      ? faFacebook
                      : null
                  }
                />
              </a>
            ))}
          </div>
        </div>
        <div className="w-1/4 lg:hidden ">
          <Button
            onClick={() => setShowSidebar(!showSidebar)}
            style={"w-[50px]"}
            text={
              showSidebar ? (
                <FontAwesomeIcon icon={Icons.Close} />
              ) : (
                <FontAwesomeIcon icon={Icons.Bars} />
              )
            }
          />
        </div>
      </nav>

      {/* Sidebar */}
      <div
        className={`sm:W-[100%] fixed w-[100%] z-[1] h-[100vh] flex items-center justify-center lg:hidden top-0 right-0 bg-gray-100 p-10 ${
          showSidebar ? "block" : "hidden"
        }`}
      >
        <div id="sidebar" className=" text-center  p-">
          {links.map(([title, url]) => (
            <a
              key={title}
              href={url}
              className=" block mt-4 font-semibold"
              onClick={() => {
                setShowSidebar(false); // Hide the sidebar when a link is clicked
                handleNavLinkClick(title); // Handle nav link click
              }}
            >
              {title}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Navbar;
