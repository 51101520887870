// import React from "react";
// import videoSrc from "../assets/videos/Asmatech Global Video 1.mp4";
// import {
//   PrimaryHeading,
//   PrimaryText,
//   SecondaryHeading,
//   SubHeading,
// } from "../components/Text";

// const Company = () => {
//   const logos = [
//     {
//       id: 1,
//       src: require("../assets/images/company/fbr_logo-removebg-preview.png"),
//       alt: "Logo 1",
//       name: "Company A",
//     },
//     {
//       id: 2,
//       src: require("../assets/images/company/pasha-logo.png"),
//       alt: "Logo 2",
//       name: "Company B",
//     },
//     {
//       id: 3,
//       src: require("../assets/images/company/pseb.png"),
//       alt: "Logo 3",
//       name: "Company C",
//     },
//   ];

//   return (
//     <>
//       <div className="bg-gray-100 py-10">
//         <div className="flex flex-col items-center">
//           <img
//             src={require("../assets/images/Asma Tech logo.png")}
//             className="w-[80px] mb-4"
//             alt=""
//           />
//           <PrimaryHeading title={`ASMATECH GLOBAL`} style="text-center" />
//         </div>
//         <div className="flex flex-col lg:flex-row justify-center items-center mx-auto">
//           {/* Left side with video */}
//           <div className="w-full lg:w-1/2 pr-8 mb-6 lg:mb-0  ">
//             <div className="relative overflow-hidden  rounded-md shadow-lg">
//               <video
//                 muted
//                 autoPlay
//                 loop
//                 className="h-full w-full object-cover "
//               >
//                 <source src={videoSrc} type="video/mp4" />
//               </video>
//             </div>
//           </div>

//           {/* Right side with related content */}
//           <div className="lg:w-1/2 bg-white p-6 rounded-md shadow-lg">
//             <SecondaryHeading title="About Our Company" style="" />
//             <PrimaryText
//               title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//               minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//               aliquip ex ea commodo consequat."
//             />
//             <SubHeading title="Mission Statement" style="mt-3 mb-0" />
//             <PrimaryText
//               title="Our mission is to provide innovative solutions that meet the needs of
//               our clients and contribute to the betterment of society."
//             />

//             <SubHeading title="A Registered Company Of" style="mt-3" />
//             <div className="flex justify-start">
//               {logos.map((logo) => (
//                 <div
//                   key={logo.id}
//                   className="flex items-center rounded-full p-2 overflow-hidden border-2 border-[#ffe48a] bg-white mr-2"
//                 >
//                   <img
//                     src={logo.src}
//                     alt={logo.alt}
//                     className="w-16 object-cover h-auto"
//                   />
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Company;

// // import React from "react";
// // import videoSrc from "../assets/videos/Asmatech Global Video 1.mp4";
// // import {
// //   PrimaryHeading,
// //   PrimaryText,
// //   SecondaryHeading,
// //   SubHeading,
// // } from "../components/Text";

// // const Company = () => {
// //   const logos = [
// //     {
// //       id: 1,
// //       src: require("../assets/images/company/fbr_logo-removebg-preview.png"),
// //       alt: "Logo 1",
// //       name: "Company A",
// //     },
// //     {
// //       id: 2,
// //       src: require("../assets/images/company/pasha-logo.png"),
// //       alt: "Logo 2",
// //       name: "Company B",
// //     },
// //     {
// //       id: 3,
// //       src: require("../assets/images/company/pseb.png"),
// //       alt: "Logo 3",
// //       name: "Company C",
// //     },
// //   ];
// //   return (
// //     <>
// //       <div className="bg-gray-100 py-10">
// //         <div className="flex flex-row justify-center  items-center w-1/3 mx-auto ">
// //       <img
// //         src={require("../assets/images/Asma Tech logo.png")}
// //         className="w-[80px]"
// //         alt=""
// //       />
// //       <PrimaryHeading title={`ASMATECH GLOBAL`} style="mt-4 flex-1" />
// //     </div>
// //         <div className="flex p-8 ">
// //           {/* Left side with video */}
// //           <div className="w-1/2 pr-8">
// //             <div className="relative overflow-hidden rounded-md shadow-lg">
// //               {/* Replace the video source with your actual video URL */}
// //               {/* <iframe
// //             title="Company Video"
// //             width="100%"
// //             height="315"
// //             src="https://www.youtube.com/embed/your-video-id"
// //             frameBorder="0"
// //             allowFullScreen
// //             className="absolute inset-0 w-full h-full"
// //           ></iframe> */}
// //               <video muted autoPlay loop className="h-full w-full object-cover">
// //                 <source src={videoSrc} type="video/mp4" />
// //               </video>
// //             </div>
// //           </div>

// //           {/* Right side with related content */}
// //           <div className="w-1/2 bg-white p-6 rounded-md shadow-lg">
// //             <SecondaryHeading title="About Our Company" style="" />
// //             <PrimaryText
// //               title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
// //           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
// //           minim veniam, quis nostrud exercitation ullamco laboris nisi ut
// //           aliquip ex ea commodo consequat."
// //             />
// //             <SubHeading title="Mission Statement" style="mt-3 mb-0" />
// //             <PrimaryText
// //               title="Our mission is to provide innovative solutions that meet the needs of
// //           our clients and contribute to the betterment of society."
// //             />

// //             <SubHeading title="A Registered Company Of" style="mt-3" />
// //             {/* <div className="flex justify-start mt-6">
// //           {logos.map((logo) => (
// //             <div key={logo.id} className="flex items-center">
// //               <img src={logo.src} alt={logo.alt} className="w-[50px] h-auto bg-red-900  mr-2" />
// //             </div>
// //           ))}
// //         </div> */}
// //             <div className="flex justify-start ">
// //               {logos.map((logo) => (
// //                 <div
// //                   key={logo.id}
// //                   className="flex items-center rounded-[50%] p-2 overflow-hidden border-2 border-[#ffe48a] bg-white mr-2"
// //                 >
// //                   <img
// //                     src={logo.src}
// //                     alt={logo.alt}
// //                     className="w-16 object-cover h-auto"
// //                   />
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Company;

import React from "react";
import videoSrc from "../assets/videos/Asmatech Global Video 1.mp4";
import {
  PrimaryHeading,
  PrimaryText,
  SecondaryHeading,
  SubHeading,
} from "../components/Text";
import Sechead from "../components/Sechead";

const Company = () => {
  const logos = [
    {
      id: 1,
      src: require("../assets/images/company/fbr_logo-removebg-preview.png"),
      alt: "Logo 1",
      name: "Company A",
    },
    {
      id: 2,
      src: require("../assets/images/company/pasha-logo.png"),
      alt: "Logo 2",
      name: "Company B",
    },
    {
      id: 3,
      src: require("../assets/images/company/pseb.png"),
      alt: "Logo 3",
      name: "Company C",
    },
  ];

  return (
    <>
      <div className="bg-gray-100 py-10">
        <Sechead text="Founded Company" style={"mx-auto"} />
        <div data-aos="fade-down" className=" py-10 flex flex-col items-center">
          <img
            src={require("../assets/images/Asma Tech logo.png")}
            className="w-[80px] mb-4"
            alt=""
          />
          <PrimaryHeading title={`ASMATECH GLOBAL`} style="text-center" />
        </div>
        <div className=" lg:pb-10 lg:px-10 flex flex-col lg:flex-row justify-center items-center mx-auto">
          <div
            data-aos="fade-right"
            className="lg:w-1/2 px-4 lg:pr-8 mb-6 lg:mb-0"
          >
            <div className="relative overflow-hidden rounded-md shadow-lg ">
              <video muted autoPlay loop className="h-full w-full object-cover">
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
          </div>

          <div
            data-aos="fade-left"
            className="lg:w-1/2 bg-white px-10 lg:px-6 p-6 rounded-md shadow-lg"
          >
            <SubHeading title="About Our Company" style="" />
            <PrimaryText
              style={"leading-5"}
              title="As a leading IT partner, AsmaTech accelerates digital transformation for the world's largest businesses. With cutting-edge solutions and unmatched expertise, we redefine industry standards. Join us on the path to success, where the systems way is the only way forward."
            />
            <SubHeading title="Mission Statement" style="mt-3 mb-0" />
            <PrimaryText
              style={"leading-5"}
              title="Our mission is to provide innovative solutions that meet the needs of
              our clients and contribute to the betterment of society."
            />

            <SubHeading title="A Registered Company Of" style="mt-3" />
            <div className="flex justify-start">
              {logos.map((logo) => (
                <div
                  key={logo.id}
                  className="flex items-center rounded-full p-2 overflow-hidden border-2 border-[#ffe48a] bg-white mr-2"
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="w-16 object-cover h-auto"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
