import { faTwitter, faLinkedin, faGithub, faSquareXTwitter , faFacebook, faInstagram, faConnectdevelop, faDigitalOcean } from '@fortawesome/free-brands-svg-icons';
import { faPlus, faArrowDown, faLaptopCode, faPaintBrush, faMobileAlt, faBars, faArrowUp, faClose, faHeadset, faMicrochip, faPeopleGroup, faArrowsSplitUpAndLeft, faListCheck, faPenNib } from '@fortawesome/free-solid-svg-icons';


export const Icons = {
  Twitter: faTwitter,
  LinkedIn: faLinkedin,
  GitHub: faGithub,
  Facebook: faFacebook,
  Instagram: faInstagram,
  ConnectDevelop: faConnectdevelop,
  DigitalOcean: faDigitalOcean,
  Headset: faHeadset,
  Microchip: faMicrochip,
  Plus: faPlus,
  DownArrow: faArrowDown,
  mobileApp: faMobileAlt,
  uiUxDesign: faPaintBrush,
  webDevelopment: faLaptopCode,
  Bars: faBars,
  ArrowUp: faArrowUp,
  Close: faClose,
  PeopleGroup: faPeopleGroup,
  ArrowsSplitUpAndLeft: faArrowsSplitUpAndLeft,
  ListCheck: faListCheck,
  PenNib: faPenNib,
  SquareXTwitter: faSquareXTwitter,
};
