import React from "react";
import Upwork from "./Upwork";
import Works from "./Works";

export default function PortfolioContainer() {
  return (
    <div id="Work">
      <Upwork />
      <Works />
    </div>
  );
}
