import React, { useState } from "react";
import { PrimaryHeading, SecondaryText, SubHeading } from "../components/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icons } from "../assets/icons/icons";
import Button from "../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
import 'sweetalert2/dist/sweetalert2.min.css';

function Contact() {
  const [formData, setFormData] = useState({
    from_email: "",
    from_name: "",
    message: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleClick = () => {
    Swal.fire({
      title: "Thank You!",
      text: "Your form is submitted",
      icon: "success"
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear form fields
    setFormData({
      from_email: "",
      from_name: "",
      message: ""
    });

    const emailContent = {
      from_email: formData.from_email,
      from_name: formData.from_name,
      message: formData.message,
    };

    // Replace the emailjs service, template, and user ID with your actual values
    emailjs.send('service_3ybgtzs', 'template_vj76fa6', emailContent, 'eAdtG5WbE4xoLWv1o').then(() => {
      handleClick();
    });
  };

  return (
    <div
      className="bg-dark-primary contact-section py-10 md:py-20"
      id="Contact"
    >
      <div className="container flex-wrap lg:flex-nowrap flex justify-between mx-auto gap-20">
        <div data-aos="fade-right" className="contact-content lg:w-[50%]">
          <PrimaryHeading
            dataAos="zoom-in"
            style={"sm:w-[100%] lg:w-[100%]"}
            title={"Let's get in touch"}
          />
          <SecondaryText
            style={"py-12 text-justify lg:text-start"}
            title={
              "I enjoy discussing new projects and design challenges. Please share as much info, as possible so we can get the most out of our first catch-up."
            }
          />
          <SubHeading title={"Living In:"} />
          <SecondaryText style={""} title={"Karāchi, Sindh, Pakistan"} />
          <SubHeading title={"Call:"} style={"pt-6"} />
          <SecondaryText style={""} title={"+92 333-0379431"} />

          <div className="contact-content-sociallinks flex gap-8 mt-16">
               <a
              data-aos="fade-in"
              target="blank"
              href="https://twitter.com/AhsanAkram_tech"
            >
              <FontAwesomeIcon
                icon={Icons.SquareXTwitter}
                className="ease-in-out duration-500 hover:-translate-y-2 cursor-pointer text-black text-2xl"
              />
            </a>
            <a
              data-aos="fade-in"
              target="blank"
              href="https://www.facebook.com/ahsan.siddiqui.90813?mibextid=ZbWKwL"
            >
              <FontAwesomeIcon
                icon={Icons.Facebook}
                className="ease-in-out duration-500 hover:-translate-y-2 cursor-pointer text-black text-2xl"
              />
            </a>
            <a
              data-aos="fade-in"
              target="blank"
              href="https://www.linkedin.com/in/muhammad-ahsan-a-9927b4a9/"
            >
              <FontAwesomeIcon
                icon={Icons.LinkedIn}
                className="ease-in-out duration-500 hover:-translate-y-2 cursor-pointer text-black text-2xl"
              />
            </a>
          </div>
        </div>

        <div className="contact-form w-[100%] lg:w-[40%]">
          <PrimaryHeading
            dataAos="zoom-in"
            style={"sm:w-[100%] lg:w-[100%]"}
            title={"Estimate your Project?"}
          />


          <form onSubmit={handleSubmit}>
            <div className="pt-10">
              <label className="font-semibold text-xl" >What is Your Name</label>
              <input
                required
                autoComplete="off"
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleInputChange}
                className="duration-300 ease-in-out pb-1 h-6 mt-4 outline-none border-b-2 focus:border-[black] bg-transparent  border-gray-400 w-[100%]"
              />
            </div>

            <div className="pt-10">
              <label className="font-semibold text-xl">Your Email Address</label>
              <input
                required
                autoComplete="off"
                type="email"
                name="from_email"
                value={formData.from_email}
                onChange={handleInputChange}
                className="duration-300 ease-in-out pb-1 mt-4 h-6 outline-none border-b-2 focus:border-[black] bg-transparent  border-gray-400 w-[100%]"
              />
            </div>

            <div className="pt-10">
              <label className="font-semibold text-xl">How can I Help you?</label>
              <textarea
                required
                autoComplete="off"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="duration-300 mt-4 ease-in-out h-20 pb-1 outline-none border-b-2 bg-transparent focus:border-[black] border-gray-400 border-[black] w-[100%]"
              />
            </div>

            <Button type="submit" text="Send"></Button>
          </form>


        </div>
      </div>
    </div>
  );
}

export default Contact;
