import React from "react";
import Resume from "./Resume";
import Skills from "./Skills";
import Company from "./Company";

export default function ResumeContainer() {
  return (
    <div id="Resume">
      <Company />
      <Resume />
      <Skills />
    </div>
  );
}
