// import React from 'react'
// import { PrimaryHeading, SecondaryText, PrimaryText , SecondaryHeading , } from "../components/Text";
// import Button from '../components/Button';

// function Hire() {
//   return (
//     <div className='Hire-main h-[60vh] flex justify-center items-center'>

//         <div>
//          <PrimaryHeading style={"mx-auto font-bold text-white text-center sm:w-[100%] w-[100%]"} title={"Interested in working with me?"}/>
//          <div className=' flex justify-center mt-8'>
//       <Button text="Hire Me !" style="bg-dark-primary flex justify-center" />
//       </div>
//         </div>
     
//     </div>
//   )
// }

// export default Hire


import React, { useEffect } from 'react';
import { PrimaryHeading } from '../components/Text';
import Button from '../components/Button';


function Hire() {

  return (
    <div className='Hire-main h-[60vh] flex justify-center items-center'>
      <div className='Hire-content'>
        <PrimaryHeading
          dataAos='zoom-in'
          style={'mx-auto font-bold text-white text-center sm:w-[100%] w-[100%]'}
          title={'Interested in working with me?'}
        />
        <div className='flex justify-center mt-8'>
         <a href="#Contact"> <Button text='Hire Me !' style='flex bg-[#ffe48a] text-[black] font-semibold hover:bg-[#ffe47a] justify-center' /></a>
        </div>
      </div>
    </div>
  );
}

export default Hire;



// useEffect(() => {
//   // Simple fade-in animation using GSAP
//   const tl = gsap.timeline({
//     scrollTrigger: {
//       trigger: ".Hire-main",
//       start: "top 95%",
//       end: "bottom 96%",
//       scrub: 4,
//     },
//   });

//   tl.from('.Hire-content', { opacity: 0, scale: 0.7 ,  duration: 5 });
//   tl.to('.Hire-content', { opacity: 1, scale: 1 ,  duration: 5 });
// }, []);
