import React, { useEffect, useState } from "react";
import "./App.css";
import Hero from "../src/containers/Hero";
import Service from "./containers/Service";
import About from "./containers/About";
import Resume from "./containers/Resume";
import Skills from "./containers/Skills";
import Hire from "./containers/Hire";
import Faq from "./containers/Faq";
import TestimonialSlider from "./containers/TestimonialSlider";
import Contact from "./containers/Contact";
import Footer from "./containers/Footer";
import Works from "./containers/Works";
import Navbar from "./containers/Navbar";
import TopButton from "./components/TopButton";
import Upwork from "./containers/Upwork";
import AOS from "aos";
import "aos/dist/aos.css";
import ResumeContainer from "./containers/ResumeContainer";
import PortfolioContainer from "./containers/PortfolioContainer";
import TestimonialContainer from "./containers/TestimonialContainer";
import CircleLoader from "react-spinners/CircleLoader"; 

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 2 seconds
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      // Clear timeout on component unmount
      clearTimeout(loadingTimeout);
    };
  }, []); // useEffect runs only once after component mount

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 120,
      easing: "ease-in-out",
      once: true,
    });
  }, []); 

  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#ffe48a]">
          <div className="grid grid-cols-1 justify-center">
          <CircleLoader color="black" className="mx-auto"/>
        <p className="pt-2 font-bold text-xl">Please wait...</p>
        </div>
        </div>
      )}

      {!isLoading && (
        <div>
          <Hero />
          <TopButton />
          <Navbar />
          <About />
          <Service />
          <ResumeContainer />
          {/* <Works /> */}
          <PortfolioContainer />
          <TestimonialContainer />
          <Faq />
          <Contact />
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
