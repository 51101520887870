import React from 'react'
import TestimonialSlider from './TestimonialSlider'
import Hire from './Hire'

export default function TestimonialContainer() {
  return (
    <div id="Review">
        <TestimonialSlider />
        <Hire />
    </div>
  )
}
